import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]
  
  copy() {
    this.sourceTarget.type = "text"
    this.sourceTarget.select()
    document.execCommand("copy")
    this.sourceTarget.type = "hidden"
    window.getSelection().removeAllRanges()
  }
}
