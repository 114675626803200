import { install, uninstall } from "@github/hotkey"
import ApplicationController from "./application_controller"

// https://github.com/github/hotkey#usage

export default class extends ApplicationController {
  static targets = [ "shortcut" ]

  initialize() {
    if (this.hasShortcutTarget) {
      this.shortcutTarget.innerHTML = this.shortcutTarget.textContent.replace("Control+", "^")
    }
    this.observeMutations(this.toggleWhenDisabled, this.element, { attributeFilter: [ "disabled" ] })
  }

  connect() {
    if (this.disabled) return
    install(this.element)
  }

  disconnect() {
    uninstall(this.element)
  }

  toggleWhenDisabled([ mutation ]) {
    const hotkey = mutation.target
    const controllers = new Set((hotkey.dataset.controller || "").split(/\s/))

    if (hotkey.disabled) {
      controllers.delete(this.identifier)
    } else {
      controllers.add(this.identifier)
    }

    hotkey.dataset.controller = [ ...controllers ].join(" ")
  }

  get disabled() {
    return document.body.hasAttribute("data-hotkeys-disabled")
  }
}

