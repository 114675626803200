// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [ "source", "display" ]

  isBlank = (string) => (!string || string.length === 0)

  connect() {
    const icon = document.createElement('span')
    icon.classList.add('glyphicon','form-control-feedback', 'visibility-hidden')
    const formGroup = this.element.querySelector('.form-group');

    formGroup.appendChild(icon)
  }

  lookup() {
    const element = this.sourceTarget
    const packingCode = element.value

    if (this.isBlank(packingCode)) {
      this.displayCount(null)
      return;
    }

    axios.get(`/admin/meals/count.json?packing_code=${packingCode}`)
      .then((response) => {
        this.displayCount(response.data.count)
    })
  }

  displayCount(count) {
    const display = this.displayTarget
    const formGroup = this.element.querySelector('.form-group')
    const icon = this.element.querySelector('.glyphicon')

    if (!Number.isInteger(count) && this.isBlank(count)) {
      formGroup.classList.remove('has-success', 'has-error', 'has-feedback')
      icon.classList.add('visibility-hidden')

      return
    }

    const isUsed = count > 0

    formGroup.classList.add('has-feedback')
    formGroup.classList.toggle('has-success', !isUsed)
    formGroup.classList.toggle('has-error', isUsed)

    icon.classList.remove('visibility-hidden')
    icon.classList.toggle('glyphicon-remove', isUsed)
    icon.classList.toggle('glyphicon-ok', !isUsed)

    if (isUsed) {
      display.innerHTML = `Used in ${count} meals.`
    } else {
      display.innerHTML = null
    }
  }
}
