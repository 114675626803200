import ApplicationController from "./application_controller"

const BOX_KINDS = "asidebox imbox laterbox feedbox trailbox".split(" ")
const ROW_TYPES = "bundle muted noted seen topic".split(" ")
const ROW_ACTIONS = "merge mute read note collection seen trash unmute unseen done folder".split(" ")

export default class extends ApplicationController {
  static targets = [
    "checkbox", "checkboxParent", "row", "menu", "menuItem", "menuButton", "selectedCount", "selectedIdsField",
    ...ROW_ACTIONS.map(action => `${action}Button`),
    ...BOX_KINDS.map(kind => `${kind}Button`)
  ]

  static classes = [ "selected", "filings" ]

  // Actions

  select() {
    console.log("selected!")
    const { parentElement } = document.activeElement

    const focusedCheckbox = this.checkboxTargets.find(checkbox =>
      parentElement.contains(checkbox) ||
        this.checkboxParentTargets.find(target => target.contains(parentElement) && target.contains(checkbox))
    )

    focusedCheckbox?.click()
  }

  update(event) {
    console.log(this.menuTarget)
    if (event?.target.checked) {
      if (this.isShiftSelected && this.lastSelectedCheckbox) {
        this.shiftSelect(this.lastSelectedCheckbox, event.target)
      }
      this.lastSelectedCheckbox = event.target
    }

    const { ids, accountIds, accountPurposes, boxKinds, count, seenCount, bundleCount, mutedCount, topicCount } = this.selected
    const [ boxKind ] = [ ...boxKinds.size == 1 ? boxKinds : [] ]

    this.selectedIdsFieldTargets.forEach(field => field.value = ids)
    this.selectedCountTargets.forEach(target => target.dataset.count = count)
    this.classList.toggle(this.selectedClass, count > 0)
    this.menuTarget.open = count > 0

    BOX_KINDS.forEach(kind => {
      if (this[`has${this.capitalizeString(kind)}ButtonTarget`]) {
        this.toggleButton(this[`${kind}ButtonTarget`], !boxKind || boxKind == kind || bundleCount > 0)
      }
    })

    if (this.hasTrashButtonTarget) { this.toggleButton(this.trashButtonTarget, topicCount != count) }
  }

  async reset(event) {
    if ("open" in event.target && event.target.open) return
    if (event?.type == "submit") await nextFrame()
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
    this.update()
  }

  loadPostingNoteForms() {
    this.selected.ids.forEach(id => document.getElementById(`edit_note_link_posting_${id}`).click())
    this.reset()
  }

  toggleFilingsMenu({ target }) {
    this.menuTarget.classList.toggle(this.filingsClass, target.open)

    if (!target.open) {
      this.focusButtonInForm(this.folderButtonTarget)
    }
  }

  toggleCollectingsMenu({ target }) {
    if (!target.open) {
      this.focusButtonInForm(this.collectionButtonTarget)
    }
  }

  enableShiftSelect(event) {
    console.log('enabling')
    if (event.key == "Shift") { this.shiftSelectEnabled = true }
  }

  disableShiftSelect(event) {
    if (event.key == "Shift") { this.shiftSelectEnabled = false }
  }

  shiftSelect(startTarget, endTarget) {
    const start = this.checkboxTargets.indexOf(startTarget)
    const end = this.checkboxTargets.indexOf(endTarget)
    this.checkboxTargets.slice(Math.min(start, end), Math.max(start, end) + 1).forEach(checkbox => checkbox.checked = true)
  }

   dispatch(eventName, { target = this.element, detail = {}, bubbles = true, cancelable = true } = {}) {
    const type = `${this.identifier}:${eventName}`
    const event = new CustomEvent(type, { detail, bubbles, cancelable })
    target.dispatchEvent(event)
    return event
  }

   get classList() {
    return this.element.classList
  }

  // Private

  get selected() {
    console.log(this.selectedRowTargets)
    const result = { ids: [], accountIds: new Set, accountPurposes: new Set, boxKinds: new Set, count: 0 }
    ROW_TYPES.forEach(type => result[`${type}Count`] = 0)

    this.selectedRowTargets.forEach(({ dataset }) => {
      console.log(dataset)
      result.ids.push(dataset.identifier)
      result.accountIds.add(dataset.accountId)
      result.accountPurposes.add(dataset.accountPurpose)
      result.boxKinds.add(dataset.boxKind)
      result.count++
      ROW_TYPES.forEach(type => result[`${type}Count`] += type in dataset ? 1 : 0)
    })

    return result
  }

  get selectedRowTargets() {
    const { selectedCheckboxTargets } = this
    return this.rowTargets.filter(rowTarget => selectedCheckboxTargets.some(checkbox => rowTarget.contains(checkbox)))
  }

  get selectedCheckboxTargets() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get isShiftSelected() {
    return this.shiftSelectEnabled
  }

  get enabledMenuItems() {
    return this.menuItemTargets.filter(target => !target.hidden)
  }

  get enabledMenuButtons() {
    const { enabledMenuItems } = this
    return this.menuButtonTargets.filter(button => enabledMenuItems.some(item => item.contains(button)))
  }

  toggleButton(element, disabled) {
    disabled = !this.hasSelectedPostings || disabled
    const menuButtonTargets = this.menuButtonTargets.filter(target => element.contains(target))

    if ("disabled" in element) element.disabled = disabled

    for (const target of menuButtonTargets) {
      target.disabled = disabled
    }

    element.hidden = disabled
  }

  focusButtonInForm(element) {
    const button = this.menuButtonTargets.find(target => element.contains(target))

    button?.focus()
  }

  get hasSelectedPostings(){
    return this.selected.count > 0
  }

  capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

